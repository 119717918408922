.nagishli-trigger-icon {
    display: flex;
    background-color: black !important;
    border-radius: 10px !important;
    right: 30px;
    left: 0 !important;
    bottom: 0 !important;
    top: -40px;
    height: 50px !important;
    width: 50px !important;
    padding: 5px !important;
}

@media screen and (max-width: 600px) {
    .nagishli-trigger-icon {
        height: 40px !important;
        width: 40px !important;
        padding: 0px !important;
    }
}