.banner-container1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 100px;
  height: 400px;
  gap: 20%;
  width: 100%;
  color: white;
}

.photo {
  background-size: cover;
  padding: 5px;
  margin-top: 110px;
  height: 380px;
  width: 380px;
  border-radius: 50%;
  filter: brightness(80%);
  background-image: url(../../public/assets/smile.png);
}

.buttons-about {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.buttons-about a {
  display: flex;
}

.buttons-about button {
  background-color: #0c0c0c;
  box-shadow: 0px 0px 9px -1px #5cb0ff;
  border-radius: 10px;
}

.buttons-about button:hover {
  background-color: black;
  box-shadow: 0px 0px 20px -1px #67a5df;
}

.links-about {
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 40%;
  padding: 10px;
}

.links-about h1 {
  font-size: 50px;
  color: rgba(255, 182, 120, 0.7803921569);
  font-family: "Orbitron", sans-serif;
  letter-spacing: 0.2rem;
  margin-bottom: 16px;

}

.links-about b {
  font-size: 34px;
  color: #75beff;
  font-family: "Orbitron", sans-serif;
  letter-spacing: 0.1rem;
}

.links-about p {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  margin-bottom: 12px !important;
  color: #a7a7a7;
}

.banner-container1 a {
  height: 50px;
  width: 200px;
  display: inline-block;
  padding: 6px 16px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.75;
  border-radius: 4px;
}

/* Styles for screens between 1613px and 1149px */
@media screen and (max-width: 1613px) and (min-width: 1149px) {
  .photo {
    margin-top: 150px;
  }
}


@media screen and (max-width: 1148px) {
  .photo {
    height: 290px;
    width: 290px;
  }

  .links-about {
    width: 80%;
    align-items: center;
  }

  .links-about b {
    font-size: 28px;
  }

  .buttons-about {
    justify-content: center;
  }

  .banner-container1 {
    margin-top: 0;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 524px) {
  .photo {
    margin-top: 240px !important;
  }
}

@media screen and (max-width: 776px) {
  .links-about {
    width: 80%;
  }

  .links-about b {
    padding: 10px;
    font-size: 16px;
  }

  .links-about P {
    font-size: 18px;
    text-align: center;
  }

  .photo {
    margin-top: 150px;
    height: 290px;
    width: 290px;
  }
}

@media screen and (max-width: 420px) {
  .photo {
    margin-top: 200px;
    height: 270px;
    width: 270px;
  }

  .buttons-about {
    width: 90% !important;
  }

  .buttons-about button {
    height: 100% !important;
    width: 100% !important;
    font-size: 12px !important;
  }

  .links-about {
    width: 100%;
    padding: 6px;
  }

  .links-about h1 {
    padding: 12px;
  }

  .links-about p {
    font-size: 16px;
    padding: 2px;
  }

  .links-about b {
    font-size: 15px;
  }
}

@media screen and (max-width: 374px) {
  .links-about {
    width: 100%;
  }

  .links-about h1 {
    font-size: 54px;
  }

  .links-about b {
    font-size: 11.7px;
  }

  .links-about p {
    font-size: 14px;
  }

  .photo {
    margin-top: 220px !important;
    height: 240px;
    width: 240px;
  }

  .buttons-about button {
    height: 100% !important;
    width: 100% !important;
    font-size: 10px !important;
    padding: 5px;
  }
}