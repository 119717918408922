h4 {
    font-family: "Orbitron", sans-serif !important;
    font-size: 36px !important;
    margin-bottom: 50px !important;
}

form label {
    font-weight: bold !important;
    color: #919191 !important;
}

.my-form-btn:hover {
    box-shadow: 0px 0px 12px -1px #fdc65ec7 !important;
}

.form-icons {
    display: flex;
    align-items: center;
    gap: 6px;
}


.form-icons2 {
    display: flex;
    align-items: center;
    gap: 6px;
}
@media screen and (max-width:396px) {
    .form-icons {
        flex-direction: column;
    }
}

