@import url("https://fonts.googleapis.com/css2?family=Orbitron&family=Oswald:wght@200;400&family=Playfair+Display:wght@900&display=swap");

.skills-container2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 800px;
  width: 100%;
  margin-top: 400px;
  background-color: rgba(0, 0, 0, 0);
  color: white;
}

.skills-container2 h1 {
  font-size: 36px;
  margin-top: 32px;
  font-family: "Orbitron", sans-serif;
  letter-spacing: 0.2rem;
  color: #ff983f;
}

.Cardframe {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
  width: 80%;
  padding: 30px;
  margin-top: 30px;
  margin-bottom: 28px;
  margin: auto;
}

.Card3 {
  width: 400px;
  height: 530px;
  border: 2px solid #5f510f;
  border-radius: 10px;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.274);
  overflow: hidden;
  background-color: #1d2129;
}

.Card3 h1 {
  text-align: center;
  color: #1aade7;
  padding: 6px;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.281);
}

.Card3 p {
  font-size: 17px;
  padding: 8px 10px 15px 12px;
  color: #9f9f9f;

}

.Card3 b {
  color: #fdc65ec7;
  font-size: 24px;
}

.Card3 svg {
  display: inline-block;
  padding: 4px;
  cursor: pointer;
}


.Card3:hover {
  transition: 0.2s;
  cursor: pointer;
  box-shadow: 0px 0px 10px 1px #5f510f;
}

.card-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 4px;
}


.icons1 {
  display: flex;
  align-items: center;
}

.card-icons svg {
  background-color: #10101073;
  border-radius: 50%;
  padding: 4px;
}


@media screen and (max-width: 1614px) {

  .Card3 b {
    font-size: 20px;
    padding: 0;
  }

  .skill-h1 {
    margin-bottom: 50px;
  }

  .card-icons {
    margin-top: 38px;
  }

}

@media screen and (max-width: 524px) {

  .skills-container2 {
    margin-top: 500px;
  }

  .Card3 p {
    font-size: 15px;
  }

  .Card3 b {
    font-size: 18px !important;
  }

}

@media screen and (width:390px) {
  .special-p2 {
    margin-bottom: 40px !important;
  }

  .Card3 p {
    font-size: 15.5px;
  }

  .Card3 b {
    font-size: 18.5px !important;
  }
}

@media screen and (max-width:390px) {
  .special-p {
    margin-bottom: -10px !important;
  }
}

@media screen and (max-width:361px) {

  .Card3 p {
    font-size: 13.5px;
  }

  .Card3 b {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 325px) {
  .skills-container2 {
    margin-top: 450px !important;
  }

  .card-icons {
    padding: 10px;

  }

  .Card3 p {
    font-size: 13px;
  }

  .Card3 b {
    font-size: 16px !important;
  }

  .special-p2 {
    padding-bottom: 28px !important;
  }
}