.projects-container3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 700px;
    width: 100%;
    margin-top: 130px;
    background-color: rgba(0, 0, 0, 0);
    margin-bottom: -600px;
}

.projects-container3 a {
    text-decoration: none !important;
}

.projects-container3 h1 {
    font-size: 36px;
    margin-top: 40px;
    font-family: "Orbitron", sans-serif;
    letter-spacing: 0.2rem;
    color: #ff983f;
    text-align: center;
}

.one-card-frame {
    padding: 10px;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    min-height: 810px;
    padding: 20px;
    margin-top: 60px;
}

.one-card {
    width: 480px;
    max-height: 600px;
    background-color: #15181d;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.199);
    border-radius: 12px;
    text-align: center;
    margin-top: 20px;
}

.image-container {
    position: relative;
    max-height: 200px;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.image-container img {
    width: 100%;
    height: 100%;
    display: block;
    filter: brightness(80%);
}

.card-content {
    padding: 14px;
    text-align: left;
}

.card-content p {
    font-size: 17.5px;
    margin: 10px 0 20px 10px;
    color: #9f9f9f;
}

.card-content p b {
    color: #fdc65ec7;
    font-size: 20px;
}

.projects-container3 .one-card h1 {
    color: #1aade7;
    font-size: 30px;
    margin: 20px 0;
}

.card-content svg {
    cursor: pointer;
}

@media screen and (max-width:450px) {
    .one-card h1 {
        font-size: 22px !important;
    }

    .card-content h2 {
        font-size: 22px;
    }

    .card-content p {
        font-size: 16px;
    }
}

@media screen and (max-width: 1875px) {
    .projects-container3 {
        height: 1500px;
        margin-bottom: 0;
    }
}


@media screen and (max-width: 1614px) {
    .projects-container3 {
        margin-top: 200px;
    }
}

@media screen and (max-width: 1237px) {
    .projects-container3 {
        height: 3500px;
    }
}

@media screen and (max-width: 543px) {
    .card-content svg {
        margin-top: 0px !important;
    }
    .projects-container3 {
        height: 3000px;
    }
}

@media screen and (max-width: 453px) {
    .card-content svg {
        margin-top: 60px !important;
    }

    .card-content p b {
        font-size: 18px;
    }

    .card-content p {
        font-size: 15px;
    }
}

@media screen and (max-width: 350px) {
    .card-content svg {
        margin-top: 10px !important;
    }

    .projects-container3 {
        height: 2700px;
    }

    .projects-container3 h1 {
        font-size: 30px;
    }
}