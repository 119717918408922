@import url('https://fonts.cdnfonts.com/css/digital-7-mono');
@import url('https://fonts.cdnfonts.com/css/digital-gothic');

.footer-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #0c0c0ce8;
    box-shadow: 0px 16px 20px 10px #17aaff;
    margin-top: 800px;
    height: 120px;
    color: white;
}

.footer-content p {
    margin-bottom: 5px;
    font-size: 16px;
    padding: 5px;
    color: #aaaaaaba;                                       
}

.footer-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 16px;
    gap: 30px;
}

.icon-p b {
    font-family: none;
    color: rgba(190, 199, 163, 0.773);
}

.footer-icons {
    display: flex;
    align-items: center;
    margin-top: 23px;
    gap: 8px;
}

.icon-p {
    display: flex;
    align-items: center;
    font-size: 30px !important;
    gap: 2px;
    margin-top: 20px;
}

@media screen and (max-width:1232px) {
    .footer-content p {
        font-size: 14px;
    }

    .footer-content .icon-p {
        font-size: 30px !important;
    }
}

@media screen and (max-width:990px) {
    .footer-content {
        flex-direction: column;
        padding: 5px;
    }

    .footer-container {
        height: 45vh;
    }
}

@media screen and (max-width: 635px) {
    .skills-container2 {
      margin-top: 850px;
    }
  }

@media screen and (max-width:516px) {
    .footer-content .icon-p {
        font-size: 30px !important;
    }
}

@media screen and (max-width:361px) {
    .footer-content .icon-p svg {
        font-size: 30px !important;
    }

    .footer-content .icon-p {
        font-size: 28px !important;
    }

    .footer-content p {
        font-size: 10.5px;
    }
}

@media screen and (max-width:300px) {
    .footer-container {
        width: 101%;
    }
}